export enum Progress {
  UNATTEMPTED = 'Unattempted',
  STARTED = 'Started',
  COMPLETED = 'Completed',
}

export class LearningPlanInstanceDTO {
  id: string;
  name: string;
  createdAt: string;
  topicArea: {
    id: string;
    name: string;
  };
}

export class LearningPlanInstancesResponseDTO {
  learningPlanInstances: LearningPlanInstanceDTO[];
}

export class EnrolledStudentDTO {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phase: {
    name: string;
    courseInstance: {
      id: string;
      status: Progress;
    };
  };
}
export class LearningPlanInstanceEnrollmentsResponseDTO {
  students: EnrolledStudentDTO[];
}

class LearningPlanInstanceDetailDTO extends LearningPlanInstanceDTO {
  numberOfEnrolledStudents: number;
  numberOfEnrolledStudentsLearningPlanStarted: number;
  numberOfCompletedStudents: number;
  phases: Array<{
    id: string;
    name: string;
    duration: string;
    nextPhaseId: string;
    courseInstance: {
      id: string;
      numberOfEnrolledStudents: number;
    };
  }>;
}

export class LearningPlanInstanceDetailResponseDTO {
  learningPlanInstance: LearningPlanInstanceDetailDTO;
}
export class LearningPlanEnrollmentRequestDTO {
  students: Array<{ id: string }>;
}

export class LearningPlanUnenrollmentRequestDTO {
  students: Array<{ id: string }>;
}

export class SendReminderRequestDTO {
  students: Array<{ id: string; courseInstanceId: string }>;
}
