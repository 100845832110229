import { CourseProgress } from './CourseProgress';

export class CourseInstanceDTO {
  id: string;
  course: {
    id: string;
    name: string;
  };
  numberOfEnrolledStudents: number;
  numberOfCourseCompletedStudents: number;
  startDate: string;
  endDate: string;
}

export class EnrolledStudentDTO {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  courseProgress?: CourseProgress;
}

class CourseInstanceDetailDTO extends CourseInstanceDTO {
  students: EnrolledStudentDTO[];
}

export class CourseInstancesResponseDTO {
  courseInstances: CourseInstanceDTO[];
}

export class CourseInstanceDetailResponseDTO {
  courseInstance: CourseInstanceDetailDTO;
}

export class CourseEnrollmentRequestDTO {
  students: Array<{ id: string }>;
}

export class CourseSendReminderRequestDTO {
  students: Array<{ id: string; courseInstanceId: string }>;
}

export class CourseEnrollmentResponseDTO {
  students: Array<{ id: string; status: string; reason: string }>;
}

export class CourseUnenrollmentRequestDTO {
  students: Array<{ id: string }>;
}

export class CourseUnenrollmentResponseDTO {}

export class CourseSendReminderResponseDTO {}
