import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-with-link',
  standalone: true,
  imports: [],
  templateUrl: './text-with-link.component.html',
})
export class TextWithLinkComponent {
  @Input({ required: true }) leadingText: string;
  @Input() trailingText?: string;
  @Input() email?: string;
  @Input() link?: string;
}
