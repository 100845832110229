import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { switchMap, map, shareReplay, startWith } from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';
import { LicenseStatus, SubscriptionLicensesResponseDTO } from '../models/SubscriptionDTO';

@Injectable({
  providedIn: 'root',
})
export class LicenseCalculationsService {
  private readonly refresh$: Subject<void> = new Subject();
  private readonly licenses$: Observable<Array<{ id: string; status: LicenseStatus }>>;

  constructor(private readonly subscriptionService: SubscriptionService) {
    this.licenses$ = this.refresh$.pipe(
      startWith(undefined),
      switchMap(() => this.subscriptionService.getLicenses()),
      map((response: SubscriptionLicensesResponseDTO) => response.licenses),
      shareReplay(1),
    );
  }

  getAssignedLicensesCount(): Observable<number> {
    return this.licenses$.pipe(
      map(
        (licenses) =>
          licenses.filter(
            (license) => license.status === LicenseStatus.RESERVED || license.status === LicenseStatus.CONSUMED,
          ).length,
      ),
    );
  }

  getReservedLicensesCount(): Observable<number> {
    return this.licenses$.pipe(
      map((licenses) => licenses.filter((license) => license.status === LicenseStatus.RESERVED).length),
    );
  }

  getAvailableLicensesCount(): Observable<number> {
    return this.licenses$.pipe(
      map((licenses) => licenses.filter((license) => license.status === LicenseStatus.AVAILABLE).length),
    );
  }

  getTotalLicensesCount(): Observable<number> {
    return this.licenses$.pipe(map((licenses) => licenses.length));
  }

  getAvailableToTotalLicensesRatio(): Observable<number> {
    return combineLatest([this.getAssignedLicensesCount(), this.getTotalLicensesCount()]).pipe(
      map(([assigned, total]) => {
        const available = total - assigned;
        return total > 0 ? (available / total) * 100 : 0;
      }),
      shareReplay(1),
    );
  }

  refreshLicenses(): void {
    this.refresh$.next();
  }
}
