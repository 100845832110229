import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LinkComponent } from '../link/link.component';
import { ButtonRowComponent } from '../button-row/button-row.component';
import { PrimaryButtonComponent } from '../primary-button/primary-button.component';
import { CommonModule } from '@angular/common';

interface ButtonRowData {
  confirmButtonText: string;
  confirmButtonAction: () => void;
  declineButtonText: string;
  confirmButtonDisabled?: boolean;
  confirmButtonLoading: boolean;
}
interface DialogData {
  headline: string;
  closable: boolean;
  description?: string;
  linkDescription?: string;
  linkHref?: string;
  footerDescription?: string;
  footerLinkDescription?: string;
  footerLinkAction?: () => void;
  additionalFooterDescription?: string;
  additionalFooterLinkDescription?: string;
  additionalFooterLinkAction?: () => void;
  buttonRowData?: ButtonRowData;
  confirmButtonText?: string;
  confirmButtonAction?: () => void;
  topButtonFooter?: {
    text: string;
    action: () => void;
    isLoading?: boolean;
    disabled?: boolean;
  };
  contentTemplate?: TemplateRef<Element>;
}

const DIALOG_POSITION_TOP = '130px';

@Component({
  standalone: true,
  imports: [LinkComponent, MatDialogModule, ButtonRowComponent, PrimaryButtonComponent, CommonModule],
  templateUrl: './dialog.component.html',
})
export class DialogViewComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: DIALOG_POSITION_TOP });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onFooterTopButtonClick(): void {
    this.data.topButtonFooter?.action();
  }

  confirmButtonClickFromButtonRow(): void {
    this.data.buttonRowData?.confirmButtonAction();
  }

  confirmButtonClick(): void {
    this.data.confirmButtonAction?.();
  }
}
