import {
  EnrolledStudentDTO,
  LearningPlanEnrollmentRequestDTO,
  LearningPlanUnenrollmentRequestDTO,
  SendReminderRequestDTO,
} from '../models/LearningPlanDTO';
import { LearningPlanEnrolledStudentUIModel } from '../models/LearningPlanEnrolledStudentUIModel';

export function toLearningPlanEnrollmentRequestDTO(students: Array<{ id: string }>): LearningPlanEnrollmentRequestDTO {
  const learningPlanEnrollmentRequestDTO = new LearningPlanEnrollmentRequestDTO();
  learningPlanEnrollmentRequestDTO.students = students;
  return learningPlanEnrollmentRequestDTO;
}

export function toLearningPlanUnenrollmentRequestDTO(
  students: LearningPlanEnrolledStudentUIModel[],
): LearningPlanUnenrollmentRequestDTO {
  const learningPlanUnenrollmentRequestDTO = new LearningPlanUnenrollmentRequestDTO();
  learningPlanUnenrollmentRequestDTO.students = students.map((student) => ({ id: student.id }));
  return learningPlanUnenrollmentRequestDTO;
}

export function toLearningPlanEnrolledStudentUIModel(
  students: EnrolledStudentDTO[],
): LearningPlanEnrolledStudentUIModel[] {
  return students.map((student) => {
    const username = `${student.firstName} ${student.lastName}`;
    const { email, id } = student;
    return {
      id,
      username,
      email,
      currentPhase: student.phase.name,
      status: student.phase.courseInstance.status,
      courseInstanceId: student.phase.courseInstance.id,
    };
  });
}

export function toSendReminderRequestDTO(students: LearningPlanEnrolledStudentUIModel[]): SendReminderRequestDTO {
  const reminderRequestDTO = new SendReminderRequestDTO();
  reminderRequestDTO.students = students.map((student) => ({
    id: student.id,
    courseInstanceId: student.courseInstanceId,
  }));
  return reminderRequestDTO;
}
