import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sep-bar-chart',
  standalone: true,
  imports: [],
  templateUrl: './sep-bar-chart.component.html',
  styleUrl: './sep-bar-chart.component.css',
})
export class SepBarChartComponent {
  @Input({ required: true }) percentage: number;
}
