<div
  class="rounded-xl border border-grey-lightGrey px-4 py-5 shadow-sm"
  *transloco="let t"
  @easeInFast
>
  @if (type === 'basic') {
    <p class="text-sm font-semibold">
      {{ t('portal.subscription.licenses.licenseQuotaHeadline') }}
    </p>
  }
  @if (type === 'enrollment') {
    <p class="text-sm font-semibold">
      {{ enrollmentDescription }}
    </p>
  }

  <p class="mb-4 text-sm font-normal">
    {{
      t('portal.subscription.licenses.licenseQuotaDescription', {
        availableLicensesCount: (availableLicensesCount$ | async),
        totalLicensesCount: (totalLicensesCount$ | async)
      })
    }}
    @if (availableToTotalLicensesRatio <= availableToTotalLicenseThreshold) {
      <app-text-with-link
        [leadingText]="
          t('portal.courses.enrollment.licenses.requestLicensesEmail')
        "
        [trailingText]="
          t('portal.courses.enrollment.licenses.requestLicensesEmailEnding')
        "
        [email]="supportEmail"
      />
    }
  </p>

  <app-sep-bar-chart
    [percentage]="availableToTotalLicensesRatio"
  ></app-sep-bar-chart>
</div>
