import {
  CourseEnrollmentRequestDTO,
  CourseInstanceDetailResponseDTO,
  CourseSendReminderRequestDTO,
  CourseUnenrollmentRequestDTO,
  EnrolledStudentDTO,
} from '../models/CourseDTO';
import { CourseInstanceUIModel } from '../models/CourseInstanceUIModel';
import { CourseEnrolledStudentUIModel } from '../models/CourseEnrolledStudentUIModel';

export function toCourseEnrollmentRequestDTO(students: Array<{ id: string }>): CourseEnrollmentRequestDTO {
  const courseEnrollmentRequestDTO = new CourseEnrollmentRequestDTO();
  courseEnrollmentRequestDTO.students = students;
  return courseEnrollmentRequestDTO;
}

function toEnrolledStudentUIModel(students: EnrolledStudentDTO[]): CourseEnrolledStudentUIModel[] {
  return students.map((student) => {
    const username = `${student.firstName} ${student.lastName}`;
    const { email, id, courseProgress } = student;
    return {
      id,
      username,
      email,
      courseProgress,
    };
  });
}

export function toCourseInstanceUIModel(
  courseInstanceDetailResponse: CourseInstanceDetailResponseDTO,
): CourseInstanceUIModel {
  return {
    id: courseInstanceDetailResponse.courseInstance.id,
    course: { name: courseInstanceDetailResponse.courseInstance.course.name },
    students: toEnrolledStudentUIModel(courseInstanceDetailResponse.courseInstance.students),
  };
}

export function toCourseUnenrollmentRequestDTO(students: CourseEnrolledStudentUIModel[]): CourseUnenrollmentRequestDTO {
  const courseUnenrollmentRequestDTO = new CourseEnrollmentRequestDTO();
  courseUnenrollmentRequestDTO.students = students.map((student) => ({ id: student.id }));
  return courseUnenrollmentRequestDTO;
}

export function toSendReminderRequestDTO(
  students: CourseEnrolledStudentUIModel[],
  courseInstanceId: string,
): CourseSendReminderRequestDTO {
  const reminderRequestDTO = new CourseSendReminderRequestDTO();
  reminderRequestDTO.students = students.map((student) => ({ id: student.id, courseInstanceId: courseInstanceId }));
  return reminderRequestDTO;
}
