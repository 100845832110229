export enum LicenseStatus {
  AVAILABLE = 'Available',
  CONSUMED = 'Consumed',
  RESERVED = 'Reserved',
}

export class LicensesResponseDTO {
  id: string;
  status: LicenseStatus;
}
export class SubscriptionLicensesResponseDTO {
  licenses: LicensesResponseDTO[];
}

class InvoiceResponseDTO {
  description: string;
  total: number;
  totalExcludingTaxAndDiscount: number;
  unitAmountExcludingTaxAndDiscount: number;
  discountAmount: number;
  taxAmount: number;
  taxPercentage: number;
  quantity: number;
  periodStart: string;
  periodEnd: string;
}

class ProductResponseDTO {
  type: string;
  courses: Array<{
    name: string;
  }>;
}

export class SubscriptionBillingResponseDTO {
  upcomingInvoice: InvoiceResponseDTO;
  product: ProductResponseDTO;
}

export class InvoicePreviewDTO {
  description: string;
  discountAmount: number;
  taxAmount: number;
  taxPercentage: number;
  total: number;
  unitAmountExcludingTax: number;
}

export class UpgradePreviewResponseDTO {
  newPlan: InvoicePreviewDTO;
  priceOfUpgrade: InvoicePreviewDTO;
}
