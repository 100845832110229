<div class="w-full">
  @if (data.headline) {
    <div class="mb-3 pt-8 text-center text-3xl font-bold">
      {{ data.headline }}
    </div>
  }
  <div class="mb-2 text-center">
    <mat-dialog-content>
      <div>
        {{ data.description }}
        @if (data.linkDescription) {
          <app-link
            [href]="data.linkHref"
            [description]="data.linkDescription"
          ></app-link>
        }
        @if (data.contentTemplate) {
          <ng-container *ngTemplateOutlet="data.contentTemplate"></ng-container>
        }
      </div>
    </mat-dialog-content>
  </div>
  <div
    class="flex w-full items-center justify-center rounded-b-lg bg-gray-100 text-center"
  >
    <div class="flex w-full flex-col items-center p-2 px-4">
      @if (data.topButtonFooter?.text) {
        <div class="w-full px-8 pb-4 pt-8">
          <app-primary-button
            [text]="data.topButtonFooter?.text ?? ''"
            (buttonClicked)="onFooterTopButtonClick()"
            [loading]="data.topButtonFooter?.isLoading ?? false"
            [disabled]="data.topButtonFooter?.disabled ?? false"
          ></app-primary-button>
        </div>
      }
      @if (data.footerDescription) {
        <div class="p-2 text-sm font-normal text-grey">
          {{ data.footerDescription }}
          @if (data.footerLinkDescription) {
            <app-link
              [description]="data.footerLinkDescription"
              [clickAction]="data.footerLinkAction"
            ></app-link>
          }
        </div>
      }

      @if (data.additionalFooterDescription) {
        <div class="p-2 text-sm font-normal text-grey">
          {{ data.additionalFooterDescription }}
          @if (data.additionalFooterLinkDescription) {
            <app-link
              [description]="data.additionalFooterLinkDescription"
              [clickAction]="data.additionalFooterLinkAction"
            ></app-link>
          }
        </div>
      }

      @if (data.buttonRowData) {
        <app-button-row
          class="w-80"
          [primaryButtonText]="data.buttonRowData.confirmButtonText"
          (primaryButtonClickedEvent)="confirmButtonClickFromButtonRow()"
          [isLoading]="data.buttonRowData.confirmButtonLoading"
          [secondaryButtonText]="data.buttonRowData.declineButtonText"
          (secondaryButtonClickedEvent)="closeDialog()"
          [disablePrimaryButton]="
            data.buttonRowData.confirmButtonDisabled ?? false
          "
        ></app-button-row>
      }

      @if (
        data.confirmButtonText &&
        !data.buttonRowData &&
        data.confirmButtonAction
      ) {
        <app-primary-button
          class="w-3/4 p-2"
          [text]="data.confirmButtonText"
          (buttonClicked)="confirmButtonClick()"
        ></app-primary-button>
      }
    </div>
  </div>
</div>
