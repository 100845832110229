import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimaryButtonComponent } from '../primary-button/primary-button.component';
import { SecondaryButtonComponent } from '../secondary-button/secondary-button.component';

@Component({
  selector: 'app-button-row',
  standalone: true,
  imports: [PrimaryButtonComponent, SecondaryButtonComponent],
  templateUrl: './button-row.component.html',
})
export class ButtonRowComponent {
  @Input({ required: true }) primaryButtonText: string;
  @Input({ required: true }) secondaryButtonText: string;
  @Input() disablePrimaryButton = false;
  @Input() isLoading = false;

  @Output() primaryButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondaryButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();

  handlePrimaryButtonClick() {
    this.primaryButtonClickedEvent.emit();
  }

  handleSecondaryButtonClick() {
    this.secondaryButtonClickedEvent.emit();
  }
}
