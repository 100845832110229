<button
  type="button"
  [disabled]="disabled"
  [ngClass]="{
    'w-full px-4 py-2 rounded-lg border border-grey-midGrey items-center justify-center inline-flex': true,
    'bg-grey-midGrey': disabled,
    'bg-white': !disabled
  }"
  (click)="handleClick()"
  matRipple
  [matRippleDisabled]="disabled"
>
  @if (loading) {
    <div class="w-1/12">
      <app-loading />
    </div>
  } @else {
    @if (icon) {
      <mat-icon class="material-icons-outlined mr-2 text-grey">{{
        icon
      }}</mat-icon>
    }
    <span class="self-center text-base font-medium text-primary">{{
      text
    }}</span>
  }
</button>
