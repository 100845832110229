<div class="container mx-auto">
  <div class="flex justify-center space-x-4 py-4">
    <app-secondary-button
      class="w-11/12"
      [text]="secondaryButtonText"
      (buttonClicked)="handleSecondaryButtonClick()"
    ></app-secondary-button>
    <app-primary-button
      class="w-11/12"
      [text]="primaryButtonText"
      (buttonClicked)="handlePrimaryButtonClick()"
      [disabled]="disablePrimaryButton"
      [loading]="isLoading"
    ></app-primary-button>
  </div>
</div>
