import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { CurrentUserState } from '../../../../core/store/current-user/current-user.state';
import { PortalApiRepository } from '../../../../core/repositories/portal-api-repository';
import {
  SubscriptionBillingResponseDTO,
  SubscriptionLicensesResponseDTO,
  UpgradePreviewResponseDTO,
} from '../models/SubscriptionDTO';
import {
  SubscriptionReactivateRequestDTO,
  SubscriptionReactivateResponseDTO,
} from '../models/SubscriptionReactivateDTOs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService extends PortalApiRepository {
  constructor() {
    super();
  }

  getLicenses(): Observable<SubscriptionLicensesResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    if (!subscriptionId) {
      return EMPTY;
    }

    const path = `/subscriptions/${subscriptionId}/Licenses`;

    return this.get<SubscriptionLicensesResponseDTO>(path);
  }

  reactivateSubscription(): Observable<SubscriptionReactivateRequestDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    if (!subscriptionId) {
      return EMPTY;
    }

    const path = `/subscriptions/${subscriptionId}/reactivate`;

    return this.post<SubscriptionReactivateResponseDTO, SubscriptionReactivateRequestDTO>(path);
  }

  getBillingInformation(): Observable<SubscriptionBillingResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    if (!subscriptionId) {
      return EMPTY;
    }

    const path = `/subscriptions/${subscriptionId}/Billing`;

    return this.get<SubscriptionBillingResponseDTO>(path);
  }

  getUpgradePriceInformation(newQuantity: number): Observable<UpgradePreviewResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    if (!subscriptionId) {
      return EMPTY;
    }

    const path = `/subscriptions/${subscriptionId}/Billing/UpgradePreview`;

    const queryParams = new HttpParams().set('new-quantity', newQuantity);

    return this.get<UpgradePreviewResponseDTO>(path, queryParams);
  }

  upgradeSubscription(newQuantity: number): Observable<void> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    if (!subscriptionId) {
      return EMPTY;
    }

    const path = `/subscriptions/${subscriptionId}/Billing/Upgrade`;

    const queryParams = new HttpParams().set('new-quantity', newQuantity);

    return this.post<void, void>(path, undefined, queryParams);
  }
}
